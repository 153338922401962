@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Tinos&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Indie+Flower&family=Tinos&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.left {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: lightblue;
  display: inline-block;
}

.font {
  font-family: "Indie Flower", cursive;
  color: rgba(235, 229, 229, 0.411);
  font-size: 2em;
}
.font2 {
  font-family: "Caveat", cursive;
  font-family: "Courier New", Courier, monospace;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-family: cursive;
  font-style: italic;
  font-size: 20px;
}
.todo {
  border-bottom: 1px solid rgb(0, 0, 0, 0.3);
  color: black;
  padding: 10px 0px;
  font-size: 24px;
}
#sub-btn {
  color: white;
  background-image: linear-gradient(
    to right,
    rgb(94, 27, 133),
    rgb(197, 130, 228)
  );
  font-size: 1rem;
}
.logo {
  position: fixed;
  top: -50px;
  left: -50px;
  width: 200px;
}
.right {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: pink;
  display: inline-block;
}

.left2 {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: pink;
  display: inline-block;
}
.right2 {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: lightblue;
  display: inline-block;
}

.main-box {
  /* background-image: url('./bg.png'); */
  background-color: rgb(225, 210, 228);
}
.c-div {
  z-index: 10;
}
.hello {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.form-b {
  position: absolute;
  color: rgb(173, 216, 230);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hello {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
}

#launch-login {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 10px;
  color: white;
  background-image: linear-gradient(
    to right,
    rgb(94, 27, 133),
    rgb(197, 130, 228)
  );
  padding:0.7rem 1.5rem;
  border:none;
  border-radius: 10px;
  font-weight: 700;
z-index: 500;
}

.form-label {
  color: rgb(12, 12, 12, 0.8);
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
  margin-left: 10%;
}

.form-input {
  width: 90%;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: 1.5px solid rgb(200, 200, 200, 0.936);
  margin-bottom: 1.6rem;
  margin-left: 5%;
  background: rgba(46, 167, 161, 0.329);
  background-color: rgb(225, 210, 228);

  box-shadow: 1px 2px 2px rgba(5, 82, 78, 0.884);
}

.submit-button {
  background: rgba(3, 88, 84, 0.534);
  color: white;
  font-weight: 600;
  width: 60%;
  height: 2.5rem;
  border: none;
  align-self: center;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 1px 2px 2px rgba(60, 63, 63, 0.884);
  margin-bottom: 1rem;
  color: white;
  background-image: linear-gradient(
    to right,
    rgb(94, 27, 133),
    rgb(197, 130, 228)
  );

}
.submit-c {
  text-align: center;
  align-items: center;
}
.or {
  text-align: center;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 100;
  display: grid;
  line-height: 10px;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 5px 0;
  grid-gap: 20px;
}

.or:after,
.or:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #9da19f98;
}

.contain-contact {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.main-contact {
  width: 450px;
  background-color: rgba(148, 143, 171, 0.3);
  border-radius: 10px;
  color: white;
  padding: 10px;
  margin-left: calc(50% - 225px);
}

.input-contact {
  width: 90%;
  margin: 0.8rem;
  padding: 0.5rem;
  color: white;
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid gray;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.8;
}

.heading {
  text-align: center;
  overflow-y: hidden;
  font-size: 2rem;
  overflow-y: hidden;
}
.heading,
.heading p,
.heading h1 {
  overflow-y: hidden;
}
.heading p {
  font-size: 1.5rem;
  overflow-y: hidden;
}

.form-contact {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 90%;
  margin-left: 5%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  width: 90%;
  border-radius: 15px;
  background-color: transparent;
  border: 2px solid gray;
  padding: 0.5rem;
  margin: 0.8rem;
}
#submit {
  background-color: transparent;
  border: 1.5px solid red;
  transition: 0.5s;
}
#submit:hover {
  background-color: red;
  color: white;
}

.name {
  padding: 1.5rem;
}

.error {
  text-align: left;
  color: red;
  margin: 0rem;
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
}
.success {
  text-align: center;
  color: green;
  margin: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.close {
  position: absolute;
  top: 0;
  right: 10px;
}

.modal-title {
  text-align: center;
  font-weight: 700;
  z-index: 100;
}

.modal-link {
  color: black;
  font-weight: 600;
}
.modal-link:hover {
  cursor: pointer;
}
.modal-link-c {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 1rem;
}
.main-box{
  width: 35em;
}
.list-icon{
  font-size: 1.5em;
}


@media only screen and (max-width: 820px) {
  .main-box{
    width: 30em;
    margin-top: 70px;
  
   }
}

@media only screen and (max-width: 680px) {
#launch-login{
  font-size: 0.8rem;
}

}
@media only screen and (max-width: 580px) {
  .cc{
    background-color: #61dafb;
    z-index: 500;
  }
 .main-box{
  width: 30em;
  margin-top: 70px;

 }
 
}

@media only screen and (max-width: 480px) {
  .main-box{
    width: 25em;
    margin-top: 70px;
   }
.font{
  font-size: 1.6em;
}

#sub-btn{
  font-size: 0.8rem;
}
.list-icon{
  font-size: 1.1rem;
}
.icon-box{
}
.logo{
  display: none;
}
.font2{
  font-size: 17px;
}
}

@media only screen and (max-width: 420px) {
  .main-box{
    width: 360px
   }

}